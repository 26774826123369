const GRID_WIDTH_CHAT_WEB = 400;
const GRID_SIDE_PADDING = 8;
const GRID_GAP = 4;
const TIMESTAMP_WIDTH_AND_ITS_SIDE_MARGIN = 54;
const PROFILE_IMAGE_WIDTH = 40;

const CHAT_MOBILE_SIDE_PADDING = 48;

const THREAD_PARENT_WIDTH = 320;
const THREAD_PARENT_SIDE_PADDING = 28;
const THREAD_PARENT_GRID_MARGIN_LEFT = 12;

const THREAD_CHILD_WIDTH = 200;
const THREAD_CHILD_SIDE_PADDING = 8;
const THREAD_CHILD_MOBILE_SIDE_PADDING = 32;
const THREAD_CHILD_MOBILE_TIMESTAMP_WIDTH_AND_ITS_SIDE_MARGIN = 60;

export const MULTIPLE_FILES_IMAGE_SIDE_LENGTH = {
  CHAT_WEB: `calc(${GRID_WIDTH_CHAT_WEB / 2}px - ${(GRID_SIDE_PADDING + GRID_GAP) / 2}px)`,
  CHAT_MOBILE: `calc(50vw - ${(CHAT_MOBILE_SIDE_PADDING + PROFILE_IMAGE_WIDTH + TIMESTAMP_WIDTH_AND_ITS_SIDE_MARGIN + GRID_SIDE_PADDING + GRID_GAP) / 2}px)`,
  THREAD_PARENT_WEB: `calc(${(THREAD_PARENT_WIDTH - (THREAD_PARENT_SIDE_PADDING + PROFILE_IMAGE_WIDTH + THREAD_PARENT_GRID_MARGIN_LEFT + GRID_GAP)) / 2}px)`,
  THREAD_PARENT_MOBILE: `calc(50vw - ${(THREAD_PARENT_SIDE_PADDING + PROFILE_IMAGE_WIDTH + THREAD_PARENT_GRID_MARGIN_LEFT + GRID_GAP) / 2}px)`,
  THREAD_CHILD_WEB: `calc(${(THREAD_CHILD_WIDTH - THREAD_CHILD_SIDE_PADDING - GRID_GAP) / 2}px)`,
  THREAD_CHILD_MOBILE: `calc(50vw - ${(THREAD_CHILD_MOBILE_SIDE_PADDING + PROFILE_IMAGE_WIDTH + THREAD_CHILD_MOBILE_TIMESTAMP_WIDTH_AND_ITS_SIDE_MARGIN + GRID_SIDE_PADDING + GRID_GAP) / 2}px)`,
};
export const MULTIPLE_FILES_IMAGE_BORDER_RADIUS = {
  CHAT_WEB: '6px',
  CHAT_MOBILE: '6px',
  THREAD_PARENT_WEB: '6px',
  THREAD_PARENT_MOBILE: '6px',
  THREAD_CHILD_WEB: '6px',
  THREAD_CHILD_MOBILE: '6px',
};
export const MULTIPLE_FILES_IMAGE_THUMBNAIL_SIDE_LENGTH = '34px';
